import { render, staticRenderFns } from "./DocumentDialog.vue?vue&type=template&id=73e66561&scoped=true&"
import script from "./DocumentDialog.vue?vue&type=script&lang=js&"
export * from "./DocumentDialog.vue?vue&type=script&lang=js&"
import style0 from "./DocumentDialog.vue?vue&type=style&index=0&id=73e66561&lang=scss&scoped=true&"
import style1 from "./DocumentDialog.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73e66561",
  null
  
)

export default component.exports