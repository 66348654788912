import {
  ALL,
  LABEL_ALL_WITH_NO_STATUS,
  KEY_ALL_WITH_NO_STATUS,
  USER_SESSION_DATA_KEY,
} from '~/constants'
import { axiosErrorMessageExtractor } from '~/utils/error'

export default {
  async fetchApplications(
    { commit },
    {
      settlementStatus = [ALL],
      status = [ALL],
      currentPage = 1,
      search = '',
      type = ALL,
      sortDirection = 'desc',
      sortBy = 'createdAt',
      owner = ALL,
      startDate,
      endDate,
      leadSource = [ALL],
      partner = [ALL],
    }
  ) {
    try {
      let requestUrl = '/vehicle-request-groups/applications'
      if (status.includes(ALL)) {
        status = [ALL]
      } else if (status.includes(LABEL_ALL_WITH_NO_STATUS)) {
        status = [KEY_ALL_WITH_NO_STATUS]
      }
      const user = this.$auth.$storage.getUniversal(USER_SESSION_DATA_KEY)
      let settlementOptions

      /**
       * @reason to use Object.assign(): Convert vue observer object to normal object
       * @ref https://github.com/vuejs/Discussion/issues/292#issuecomment-385888753
       */
      settlementOptions =
        settlementStatus === ALL ? ALL : Object.assign([], settlementStatus)

      if (leadSource.includes(ALL)) {
        leadSource = [ALL]
      }

      // this is to make sure the first letter is in lowercase before passing to backend
      sortBy = sortBy.charAt(0).toLowerCase() + sortBy.slice(1)

      commit('startTableLoading')
      const request = await this.$axios.get(requestUrl, {
        params: {
          status,
          leadSource,
          partner,
          sortOrder: sortDirection,
          ...(sortBy ? { sortBy } : {}),
          ...(type !== ALL ? { type } : {}),
          ...(owner !== ALL ? { owner } : {}),
          ...(currentPage ? { page: currentPage } : {}),
          ...(search ? { search } : {}),
          ...(startDate ? { startDate: startDate.toISOString() } : {}),
          ...(endDate ? { endDate: endDate.toISOString() } : {}),
          ...(settlementOptions ? { settlementStatus: settlementOptions } : {}),
        },
      })
      const { data } = request
      commit('setTableSettings', {
        totalDocs: data.totalDocs,
        page: data.page,
        totalPages: data.totalPages,
        limit: data.limit,
        pagingCounter: data.pagingCounter,
      })

      commit('setApplications', data.docs)
    } catch (e) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(e),
      })
    } finally {
      commit('stopTableLoading')
    }
  },
  async fetchLeadsources({ commit }) {
    try {
      const { data: sources } = await this.$axios.get(
        '/vehicle-request-groups/leadsources'
      )
      return sources
    } catch (e) {
      this.$notify.error({
        title: 'Error',
        message: 'Error getting the requests :(',
      })
    }
  },
}
