//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import {
  ERROR_MESSAGE_FOR_OTHER_DOCUMENT_NAME,
  TITLE_FOR_OTHER,
  APPLICATION_STATUS_SETTLED,
} from '@/constants'
import {
  LABEL_PRIMARY_APPLICANT,
  LABEL_SECONDARY_APPLICANT,
  LABEL_ADD_DOCUMENT,
  LABEL_AVAILABLE,
  LABEL_ADD_OTHER_DOCUMENT,
  LABEL_SELECTED,
  LABEL_CANCEL,
  LABEL_EMAIL_AND_ADD,
  LABEL_ADD_ONLY,
  LABEL_OTHER_DOCUMENT_NAME,
  LABEL_SELECT_THE_OPTION_EMAIL_AND_ADD_FIRST,
  LABEL_SELECT_THE_OPTION_EMAIL_AND_ADD_SECOND,
  LABEL_SELECT_THE_OPTION_EMAIL_AND_ADD_THIRD,
  LABEL_SELECT_ONE_OR_MORE_DOCUMENT_FIRST,
  LABEL_SELECT_ONE_OR_MORE_DOCUMENT_SECOND,
  LABEL_DOCUMENT_PRIMARY,
  LABEL_DOCUMENT_SECONDARY,
  DEFAULT_DOCUMENT_TYPES,
  DEFAULT_SETTLEMENT_DOCUMENT_TYPES,
  DEFAULT_SETTLEMENT_APPLICANT_DOCUMENT_TYPES,
  LABEL_SHARED,
  LABEL_BUSINESS_DOCUMENTS,
  LABEL_ELECTRONIC_BACK_STATEMENT_LAST_3_MONTHS,
  LABEL_ELECTRONIC_BACK_STATEMENT_LAST_6_MONTHS,
  LABEL_ELECTRONIC_BACK_STATEMENT_LAST_12_MONTHS,
  LABEL_ELECTRONIC_BACK_STATEMENT_LAST_3_MONTHS_PURPOSE,
  LABEL_ELECTRONIC_BACK_STATEMENT_LAST_6_MONTHS_PURPOSE,
  LABEL_ELECTRONIC_BACK_STATEMENT_LAST_12_MONTHS_PURPOSE,
  LABEL_EMAIL_AND_ADD_IS_THE_ONLY_AVAIALABLE_ELECTRONIC_BANK_STATEMENT,
  LABEL_DOCUMENT_SETTLEMENT,
  LABEL_DOCUMENT_SETTLEMENT_DOCUMENT,
  LABEL_SETTLEMENT_PRIMARY,
  LABEL_SETTLEMENT_SECONDARY,
  LABEL_SETTLEMENT_OTHER,
  LABEL_FOR_ADD_BUTTON,
} from '@/constants/applications'
import NotificationBar from './UtilComponents/NotificationBar.vue'

export default {
  props: {
    dialogData: {
      type: Array,
      default: () => [],
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    dialogFor: {
      type: String,
      default: null,
    },
    applicantId: {
      type: String,
      default: null,
    },
    secondaryIndex: {
      type: Number,
      default: 0,
    },
    dialogLabel: {
      type: String,
      default: '',
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
    isFromSettlementTab: {
      type: Boolean,
      default: false,
    },
    documentFor: {
      type: String,
      default: null,
    },
    primaryDocumentData: {
      type: Array,
      default: () => [],
    },
    sharedDocumentData: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    NotificationBar,
  },
  data() {
    return {
      LABEL_PRIMARY_APPLICANT,
      LABEL_SECONDARY_APPLICANT,
      LABEL_SHARED,
      LABEL_ADD_DOCUMENT,
      LABEL_AVAILABLE,
      LABEL_ADD_OTHER_DOCUMENT,
      LABEL_SELECTED,
      LABEL_CANCEL,
      LABEL_EMAIL_AND_ADD,
      LABEL_ADD_ONLY,
      LABEL_OTHER_DOCUMENT_NAME,
      LABEL_SELECT_THE_OPTION_EMAIL_AND_ADD_FIRST,
      LABEL_SELECT_THE_OPTION_EMAIL_AND_ADD_SECOND,
      LABEL_SELECT_THE_OPTION_EMAIL_AND_ADD_THIRD,
      LABEL_SELECT_ONE_OR_MORE_DOCUMENT_FIRST,
      LABEL_SELECT_ONE_OR_MORE_DOCUMENT_SECOND,
      LABEL_DOCUMENT_PRIMARY,
      LABEL_DOCUMENT_SECONDARY,
      DEFAULT_DOCUMENT_TYPES,
      DEFAULT_SETTLEMENT_DOCUMENT_TYPES,
      ERROR_MESSAGE_FOR_OTHER_DOCUMENT_NAME,
      DEFAULT_SETTLEMENT_APPLICANT_DOCUMENT_TYPES,
      LABEL_BUSINESS_DOCUMENTS,
      TITLE_FOR_OTHER,
      searchDoc: null,
      availableDocs: [],
      extraDoc: null,
      showExtraInput: false,
      showOtherDocErr: false,
      showAddOtherButton: true,
      isEmailAndSubmitLoading: false,
      isSubmitLoading: false,
      LABEL_ELECTRONIC_BACK_STATEMENT_LAST_3_MONTHS,
      LABEL_ELECTRONIC_BACK_STATEMENT_LAST_6_MONTHS,
      LABEL_ELECTRONIC_BACK_STATEMENT_LAST_12_MONTHS,
      LABEL_ELECTRONIC_BACK_STATEMENT_LAST_3_MONTHS_PURPOSE,
      LABEL_ELECTRONIC_BACK_STATEMENT_LAST_6_MONTHS_PURPOSE,
      LABEL_ELECTRONIC_BACK_STATEMENT_LAST_12_MONTHS_PURPOSE,
      LABEL_EMAIL_AND_ADD_IS_THE_ONLY_AVAIALABLE_ELECTRONIC_BANK_STATEMENT,
      LABEL_DOCUMENT_SETTLEMENT,
      LABEL_DOCUMENT_SETTLEMENT_DOCUMENT,
      APPLICATION_STATUS_SETTLED,
      LABEL_SETTLEMENT_PRIMARY,
      LABEL_SETTLEMENT_SECONDARY,
      LABEL_SETTLEMENT_OTHER,
      LABEL_FOR_ADD_BUTTON,
    }
  },
  computed: {
    disableAdd() {
      return this.availableDocs.some(
        (item) =>
          this.isElectronicBankStatement(item.value) &&
          item.selected &&
          !item.added
      )
    },
    submitButtonDisableCondition() {
      return (
        (!this.isFromSettlementTab && this.disableAdd) ||
        this.isSubmitLoading ||
        this.isEmailAndSubmitLoading
      )
    },
    availableCount() {
      return this.availableDocs.filter((item) => !item.selected).length
    },
    selectedCount() {
      return this.availableDocs.filter(
        (item) => item.selected && !item.added && !item.uploadedAt
      ).length
    },
    getDialogFor() {
      if (this.dialogLabel) {
        return this.documentFor === this.LABEL_SETTLEMENT_SECONDARY
          ? LABEL_SECONDARY_APPLICANT
          : this.dialogLabel
      } else if (this.dialogFor === LABEL_DOCUMENT_PRIMARY) {
        return LABEL_PRIMARY_APPLICANT
      } else if (this.dialogFor === LABEL_SHARED) {
        return LABEL_SECONDARY_APPLICANT
      } else if (this.dialogFor === LABEL_BUSINESS_DOCUMENTS) {
        return LABEL_BUSINESS_DOCUMENTS
      } else if (this.dialogFor === LABEL_DOCUMENT_SETTLEMENT) {
        return LABEL_DOCUMENT_SETTLEMENT_DOCUMENT
      } else {
        return LABEL_SECONDARY_APPLICANT
      }
    },
  },
  watch: {
    showDialog() {
      this.setAvailableDocs()
      // eslint-disable-next-line array-callback-return
      this.dialogData.map((docToSelect) => {
        // eslint-disable-next-line array-callback-return
        this.availableDocs.map((doc) => {
          // eslint-disable-next-line no-unused-expressions
          if (!docToSelect.deletedAt) {
            if (
              doc.value === docToSelect.purpose &&
              !this.isElectronicBankStatement(doc.value)
            ) {
              doc.selected = true
              doc.added = true
              if (docToSelect.uploadedAt)
                doc.uploadedAt = docToSelect.uploadedAt
              if (docToSelect._id) doc._id = docToSelect._id
              if (docToSelect.requestedAt)
                doc.requestedAt = docToSelect.requestedAt
              doc.requestedViaEmail = docToSelect.requestedViaEmail
              if (docToSelect.emailSentAt)
                doc.emailSentAt = docToSelect.emailSentAt
            } else if (docToSelect.purpose === 'Other') {
              docToSelect.added = true
              const checkValueExists = _.map(this.availableDocs, 'value')
              if (!checkValueExists.includes(docToSelect.name))
                this.pushExtraValue(
                  docToSelect.name,
                  docToSelect.requestedAt,
                  docToSelect.added,
                  docToSelect._id
                )
            }
          }
        })
      })
    },
  },
  mounted() {
    this.$nuxt.$on('close-loader', () => {
      this.isSubmitLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },
  methods: {
    checkSettlementCondtition() {
      return [
        this.LABEL_SETTLEMENT_SECONDARY,
        this.LABEL_SETTLEMENT_PRIMARY,
        this.LABEL_SETTLEMENT_OTHER,
      ].includes(this.documentFor)
    },
    setAvailableDocs() {
      const docs = this.isFromSettlementTab
        ? this.checkSettlementCondtition()
          ? this.DEFAULT_SETTLEMENT_APPLICANT_DOCUMENT_TYPES
          : this.DEFAULT_SETTLEMENT_DOCUMENT_TYPES
        : this.DEFAULT_DOCUMENT_TYPES
      this.availableDocs = _.cloneDeep(docs)
    },
    closeDocDialog() {
      this.$emit('closeDocumentDialog')
    },
    isElectronicBankStatement(value) {
      return [
        LABEL_ELECTRONIC_BACK_STATEMENT_LAST_3_MONTHS_PURPOSE,
        LABEL_ELECTRONIC_BACK_STATEMENT_LAST_6_MONTHS_PURPOSE,
        LABEL_ELECTRONIC_BACK_STATEMENT_LAST_12_MONTHS_PURPOSE,
      ].includes(value)
    },
    getMappedNameWithPurpose(documentName) {
      const result = DEFAULT_DOCUMENT_TYPES.filter(
        (type) => type.value === documentName
      )
      return result && result[0] ? result[0].key : documentName
    },
    getValidDocumentPostfix(dataObj, count) {
      if (dataObj.length) {
        dataObj.forEach((doc) => {
          if (doc.name) {
            const matches = doc.name.includes('(')
              ? doc.name.replace(/[{()}]/g, '').split(' ')
              : ''
            if (matches && Number(matches[matches.length - 1]) >= count) {
              count = Number(matches[matches.length - 1]) + 1
            }
          }
        })
      }
      return count
    },
    checkBoxSelect(ind) {
      this.availableDocs[ind].selected = true
      if (
        this.isElectronicBankStatement(this.availableDocs[ind].value) ||
        [
          LABEL_DOCUMENT_PRIMARY,
          LABEL_SHARED,
          LABEL_BUSINESS_DOCUMENTS,
        ].includes(this.dialogFor)
      ) {
        let count = 0
        const data = this.dialogData.filter(
          (x) => x.purpose === this.availableDocs[ind].value
        )
        count = data.length
        count = this.getValidDocumentPostfix(data, count)
        if ([LABEL_SHARED, LABEL_BUSINESS_DOCUMENTS].includes(this.dialogFor)) {
          const dataObj = this.primaryDocumentData.filter(
            (x) => x.purpose === this.availableDocs[ind].value
          )
          count = count ? count : dataObj.length
          count = this.getValidDocumentPostfix(dataObj, count)
        } else if (this.dialogFor === LABEL_DOCUMENT_PRIMARY) {
          const dataObj2 = this.sharedDocumentData.filter(
            (x) => x.purpose === this.availableDocs[ind].value
          )
          count = count ? count : dataObj2.length
          count = this.getValidDocumentPostfix(dataObj2, count)
        }

        if (count) {
          this.availableDocs[ind].key =
            this.availableDocs[ind].key + ' (' + count + ')'
        }
      }
    },
    removeSelection(ind) {
      this.availableDocs[ind].selected = false
      this.availableDocs[ind].key = this.getMappedNameWithPurpose(
        this.availableDocs[ind].value
      )
    },
    addOtherDocumentInput() {
      this.showExtraInput = true
    },
    addExtraDoc() {
      if (this.extraDoc != null) {
        this.pushExtraValue(this.extraDoc)
        this.removeExtraDoc()
      } else {
        this.showOtherDocErr = true
      }
    },
    pushExtraValue(val, requestedAt = '', added = false, id = '') {
      this.availableDocs.push({
        key: val,
        value: val,
        selected: true,
        extraDoc: true,
        requestedAt: requestedAt,
        _id: id,
        added,
        ...(this.isFromSettlementTab ? { documentFor: 'Settlement' } : {}),
      })
    },
    removeExtraDoc() {
      this.extraDoc = null
      this.showExtraInput = false
    },
    getNewlyAddedDocuments({
      documents = [],
      isRequestedViaEmail = false,
      isForSettlement = false,
    }) {
      const addedDocuments = []
      documents.forEach((doc) => {
        // Checking current doc is selected or not
        if (doc.selected && !doc._id) {
          const selectedDoc = {
            requestedViaEmail: isRequestedViaEmail,
            purpose: doc.extraDoc === true ? TITLE_FOR_OTHER : doc.value,
            requestedAt: doc.requestedAt
              ? doc.requestedAt
              : new Date().toISOString(),
            name: doc.key,
            ...(isForSettlement && {
              documentFor: this.LABEL_DOCUMENT_SETTLEMENT,
            }),
          }
          addedDocuments.push(selectedDoc)
        }
      })
      return addedDocuments
    },
    async emailAndSubmit() {
      if (!this.selectedCount) {
        return
      }
      this.isEmailAndSubmitLoading = true
      let isDocumentForSettlement = false
      const applicant = this.applicantId
      if (
        this.isFromSettlementTab &&
        (this.checkSettlementCondtition() ||
          this.dialogFor === this.LABEL_DOCUMENT_SETTLEMENT)
      ) {
        isDocumentForSettlement = true
      }
      const addedDocuments = this.getNewlyAddedDocuments({
        documents: this.availableDocs,
        isRequestedViaEmail: true,
        isForSettlement: isDocumentForSettlement,
      })
      const docs = addedDocuments.concat(Object.values(this.dialogData))
      if (this.dialogFor === this.LABEL_DOCUMENT_PRIMARY) {
        await this.updateDocsWithEmail(
          docs,
          applicant,
          'updateSelectedDocumentsWithEmail'
        )
      } else if (this.dialogFor === this.LABEL_DOCUMENT_SECONDARY) {
        await this.updateDocsWithEmail(
          docs,
          applicant,
          'updateOtherSelectedDocumentsWithEmail'
        )
      } else if (this.dialogFor === this.LABEL_DOCUMENT_SETTLEMENT) {
        await this.updateDocsWithEmail(
          docs,
          applicant,
          'updateSharedSelectedDocumentsWithEmail'
        )
      } else {
        await this.updateDocsWithEmail(
          docs,
          applicant,
          'updateSharedSelectedDocumentsWithEmail',
          this.isCommercial
        )
      }
      this.isEmailAndSubmitLoading = false
      this.closeDocDialog()
    },
    async submit() {
      if (!this.selectedCount) {
        return
      }
      this.isSubmitLoading = true
      let isDocumentForSettlement = false

      // Checking for settlement document
      if (
        this.isFromSettlementTab &&
        (this.checkSettlementCondtition() ||
          this.dialogFor === this.LABEL_DOCUMENT_SETTLEMENT)
      ) {
        isDocumentForSettlement = true
      }

      // add newly added documents
      const addedDocuments = this.getNewlyAddedDocuments({
        documents: this.availableDocs,
        isRequestedViaEmail: false,
        isForSettlement: isDocumentForSettlement,
      })

      // Concat newly added document and aleady selected documents
      const docs = addedDocuments.concat(Object.values(this.dialogData))

      //  Update documents based on dialog
      if (this.dialogFor === this.LABEL_DOCUMENT_PRIMARY) {
        await this.updateDocs(docs, 'updateSelectedDocuments')
      } else if (this.dialogFor === this.LABEL_DOCUMENT_SETTLEMENT) {
        await this.updateDocs(docs, 'updateSharedSelectedDocuments')
      } else if (this.dialogFor === this.LABEL_DOCUMENT_SECONDARY) {
        await this.updateDocs(docs, 'updateOtherSelectedDocuments')
      } else {
        await this.updateDocs(docs, 'updateSharedSelectedDocuments')
      }

      this.isSubmitLoading = false
      this.closeDocDialog()
    },
    async updateDocs(selectedDocuments, actName) {
      const { id, applicationId } = this.$route.params
      await this.$store.dispatch(`applications/${actName}`, {
        selectedDocuments,
        id,
        applicationId,
        secondaryIndex: this.secondaryIndex,
      })
    },
    async updateDocsWithEmail(
      selectedDocuments,
      applicant,
      actName,
      isCommercial
    ) {
      const { id, applicationId } = this.$route.params
      await this.$store.dispatch(`applications/${actName}`, {
        applicant,
        selectedDocuments,
        id,
        applicationId,
        secondaryIndex: this.secondaryIndex,
        isCommercial,
        isFromSettlementTab: this.isFromSettlementTab,
      })
    },
  },
}
